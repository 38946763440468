const colors = {
    red: '#ce2020',
    darkRed: `#820A0A`,
    green: `#008225`,
    darkGrey: '#282828',
    lightGrey: '#d2d2d2',
    darkBlue: '#0B4062',
    offWhite: '#f5f5f5',
}

export default colors
