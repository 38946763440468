import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset-advanced'
import mediaQueries from '../constants/mediaQueries'
import colors from '../constants/colors'

const GlobalStyle = createGlobalStyle`
  ${reset}

  html{
      font-size: 100%;
      overflow-x: hidden;
      scroll-behavior: smooth;

      ${mediaQueries.md} {
        font-size: 112.5%;
      }
  
      
  }
  html, body{
    max-height: 100vh;
    overflow-y: hidden;
  }
  body{
    font-family: 'Inter', sans;
    line-height: 1.5;
    font-size: 1rem;
    background-color: ${colors.darkGrey};
    color: white;
  }
  p{
    margin-bottom: 20px;
  }
`

export default GlobalStyle
