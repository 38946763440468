/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import GlobalStyle from './global-style'
import Seo from '../components/seo'
import Header from './header'

const Layout = ({ children, theme = 'dark', title, allLink, description }) => {
    return (
        <>
            <Seo title={title} description={description} />
            <GlobalStyle />
            <Header theme={theme} allLink={allLink} />
            {children}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
