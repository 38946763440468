import React from 'react'
import styled from 'styled-components'
import Logo from '../images/logo-light.inline.svg'
import mediaQueries from '../constants/mediaQueries'
import { Link } from 'gatsby'

const S = {
    Header: styled.header`
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        padding: 20px;
        z-index: 100;

        .logo {
            fill: currentColor;
            height: 100%;
            width: auto;
            max-height: 50px;
        }

        ${mediaQueries.sm} {
            padding: 1vw;

            .logo {
                max-height: unset;
            }
        }
    `,
    Nav: styled.nav`
        ul {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 0.75em;
            font-weight: bold;

            li {
                display: inline-block;

                a {
                    opacity: 0.45;

                    &.active {
                        opacity: 1;
                        cursor: unset;
                    }
                }

                &:not(:last-child):after {
                    content: '/';
                    margin: 0 0.5em;
                }
            }
        }
    `,
    LogoLink: styled.a`
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
    `,
}

const Header = ({ allLink, theme }) => {
    return (
        <S.Header style={{ color: theme === 'dark' ? `white` : `black` }}>
            <S.LogoLink href="https://mysocialhustle.com">
                <Logo className="logo" />
            </S.LogoLink>
            <S.Nav>
                <ul>
                    <li>
                        <Link to={allLink} activeClassName="active">
                            All
                        </Link>
                    </li>
                    <li>
                        <Link to="/marketing/" activeClassName="active">
                            Marketing
                        </Link>
                    </li>
                    <li>
                        <Link to="/creative/" activeClassName="active">
                            Creative
                        </Link>
                    </li>
                </ul>
            </S.Nav>
        </S.Header>
    )
}

Header.defaultProps = {
    allLink: '/all/',
}

export default Header
